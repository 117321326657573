/*
 * @Author: sun
 * @Date: 2022-03-15 10:50:55
 * @LastEditTime: 2023-03-01 09:19:18
 * @FilePath: \cloud-platform\src\store\mutations.js
 * @Description:
 */
import VueCookies from 'vue-cookies'
const mutations = {
  setVal(state) {
    state.val++
  },
  addTab: (state, tab) => {
    // 如果tab已经存在，不添加新的tabs
    if (state.tabList.some((item) => item.path === tab.path)) {
      if (tab.path == "/Purchase") {
        if (state.tabList.some((item) => item.query.shopId != tab.query.shopId)) {
          let index = state.tabList.findIndex(item => item.path === tab.path)
          state.tabList[index] = tab
          window.sessionStorage.setItem('tablistView', JSON.stringify(state.tabList))
        }
      }
      return
    }
    state.tabList.push(tab)
    window.sessionStorage.setItem('tablistView', JSON.stringify(state.tabList))
  },
  // 删除tag 路由数据
  deleteTab: (state, data) => {
    /**
     * data 参数说明
     * index - 当前点击的tag的索引 也就是从tagList数组的第几个开始删除
     * delCount - 要删除的个数。点击关闭时默认为1，关闭全部时为tagList.length - 1
     */
    if (data == 0) {
      state.tabList = []
    } else {
      state.tabList.splice(data.index, data.delCount)
    }
  },
  // 保留当前活跃的tag页面
  setactiveTab: (state, data) => {
    state.activeTab = data
    window.sessionStorage.setItem('activetabsView', JSON.stringify(data))
  },
  // 保留当前活跃的tag页面名称
  setactivetagTitle: (state, data) => {
    state.activeTag = data
    window.sessionStorage.setItem('activetagTitle', data)
  },
  // 浏览器刷新只保留当前活跃的tag页面
  settabList: (state, data) => {
    state.tabList = data
    window.sessionStorage.setItem('tabsView', JSON.stringify(data))
  },
  // 保存当前用户dadavid
  setdadavid: (state, data) => {
    state.dadavid = data
    VueCookies.set('dadavid', data)
  },
  // 是否是管理员
  setshowCorrect: (state, data) => {
    state.showCorrect = data
    window.sessionStorage.setItem('showCorrect', data)
  },
}

export default mutations
