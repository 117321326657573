/*
 * @Author: sun
 * @Date: 2022-11-08 10:32:35
 * @LastEditTime: 2022-11-08 10:32:43
 * @FilePath: \sentrybox\src\network\el-dragDialog\index.js
 * @Description:
 */
import drag from './drag'

const install = function(Vue) {
  Vue.directive('el-drag-dialog', drag)
}

if (window.Vue) {
  window['el-drag-dialog'] = drag
  Vue.use(install);
}

drag.install = install
export default drag
