/*
 * @Author: sun
 * @Date: 2022-03-15 10:50:55
 * @LastEditTime: 2024-10-08 14:12:59
 * @FilePath: \cloud-platform\src\element\element.js
 * @Description: 
 */
import Vue from 'vue';
import {
  Button,
  Container,
  Main,
  Aside,
  Header,
  Menu,
  MenuItem,
  Submenu,
  Scrollbar,
  Form,
  FormItem,
  Input,
  Checkbox,
  Message,
  MessageBox,
  Tooltip,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Table,
  TableColumn,
  DatePicker,
  Tree,
  Row,
  Col,
  Dialog,
  Pagination,
  Option,
  Select,
  Radio,
  Tag,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Cascader,
  loading,
  Popover,
  Image,
  RadioGroup,
  Upload,
  Progress,
  Switch,
  Tabs,
  TabPane,
  InputNumber
} from 'element-ui'

Vue.use(TabPane)
Vue.use(Tabs)
Vue.use(Switch)
Vue.use(Progress)
Vue.use(RadioGroup)
Vue.use(Image)
Vue.use(Popover)
Vue.use(loading)
Vue.use(Cascader)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dropdown)
Vue.use(Tag)
Vue.use(Radio)
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Col)
Vue.use(Row)
Vue.use(Tree)
Vue.use(DatePicker)
Vue.use(TableColumn)
Vue.use(Table)
Vue.use(Card)
Vue.use(BreadcrumbItem)
Vue.use(Breadcrumb)
Vue.use(Icon)
Vue.use(Tooltip)
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$MessageBox = MessageBox
Vue.prototype.$message = Message
Vue.use(Checkbox)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(FormItem)
Vue.use(Form)
Vue.use(Scrollbar)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Menu)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Container)
Vue.use(Button)
Vue.use(Upload)
