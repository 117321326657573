/*
 * @Author: sun
 * @Date: 2022-03-15 10:50:55
 * @LastEditTime: 2022-12-29 14:53:00
 * @FilePath: \cloud-platform\src\store\state.js
 * @Description:
 */
import VueCookies from 'vue-cookies'
const state = {
  val: '7',
  tagsList: localStorage.getItem('itemtags')
    ? JSON.parse(localStorage.getItem('itemtags'))
    : [
      {
        name: '首页',
        path: 'Welcome',
      },
    ],
  // 出场录入信息
  getoutInformation:
    JSON.parse(window.sessionStorage.getItem('COMPANY_AUTH_INFO')) || {},
  tabList: [], //tag路由页面
  activeTab: window.sessionStorage.getItem('activetabsView'), // 当前活跃路由信息tag页面
  activeTag: window.sessionStorage.getItem('activetagTitle'), // 当前活跃tag页面名称
  showCorrect: window.sessionStorage.getItem('showCorrect'), // 是否是管理员
  dadavid: VueCookies.get('dadavid'), // 当前用户dadavid
}

export default state
