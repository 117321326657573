/*
 * @Author: sunfei
 * @Date: 2022-03-15 10:50:55
 * @LastEditTime: 2023-02-20 10:54:00
 * @FilePath: \cloud-platform\src\main.js
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './element/element.js'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
// 导入键盘
import LicenseKeyboard from "@/components/keyboards"
// 注册为全局组件
Vue.component("LicenseKeyboard", LicenseKeyboard)
// 全局格式化时间
import {
  conversionTime,
  conversionTime1
} from './network/conversionTime.js'
// 全局方法挂载
Vue.prototype.conversionTime = conversionTime
Vue.prototype.conversionTime1 = conversionTime1

// axios引入
import axios from 'axios'
Vue.prototype.$axios = axios

// cookies全局引入
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// 粘贴板
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// datev引入
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
//防抖处理-立即执行
const on = Vue.prototype.$on
Vue.prototype.$on = function (event, func) {
  let timer
  let flag = true
  let newFunc = func
  if (event === "click") {
    newFunc = function () {
      if (flag) {
        func.apply(this, arguments)
        flag = false
      }
      clearTimeout(timer)
      timer = setTimeout(function () {
        flag = true
      }, 800)
    }
  }
  on.call(this, event, newFunc)
}


// 注册全局 dialog 拖拽指令
import elDragDialog from './network/el-dragDialog/index.js'
Vue.directive('el-drag-dialog', elDragDialog)

// echarts引入
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')