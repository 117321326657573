import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import CryptoJS from 'crypto-js';
import store from '@/store/index'; //直接导入store对象
import { Message } from 'element-ui';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/Home',
  },
  {
    path: '/Login',
    name: '登入',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '登入',
      keepAlive: false,
    },
  },
  {
    // 数据大屏
    path: '/Outerchain',
    name: '数据大屏',
    component: () => import('../views/Outerchain/Outerchain.vue'),
    meta: {
      title: '数据大屏',
      keepAlive: true,
    },
  },
  {
    // 优惠券动态二维码
    path: '/dynamicCode',
    name: '优惠券动态二维码',
    component: () => import('../views/coupon/dynamicCode.vue'),
    meta: {
      title: '优惠券动态二维码',
      keepAlive: false,
    },
  },
  {
    // 修改密码
    path: '/changePassword',
    name: '修改密码',
    component: () => import('../views/changePassword.vue'),
    meta: {
      title: '修改密码',
      keepAlive: false,
    },
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    redirect: '/Welcome',
    children: [
      {
        // 首页
        path: '/Welcome',
        name: '首页',
        component: () => import('../views/Welcome.vue'),
        meta: {
          title: '首页',
          keepAlive: false,
        },
      },
      {
        // 欢迎页面
        path: '/helloWorld',
        name: '欢迎页',
        component: () => import('../views/helloWorld.vue'),
        meta: {
          title: '欢迎页',
          keepAlive: false,
        },
      },
      {
        // 系统管理
        path: '/Systemuser',
        name: '系统用户管理',
        component: () => import('../views/system/Systemuser.vue'),
        meta: {
          title: '系统用户管理',
          keepAlive: false,
        },
      },
      {
        path: '/Role',
        name: '角色管理',
        component: () => import('../views/system/Role.vue'),
        meta: {
          title: '角色管理',
          keepAlive: false,
        },
      },
      {
        path: '/Resources',
        name: '资源管理',
        component: () => import('../views/system/Resources.vue'),
        meta: {
          title: '资源管理',
          keepAlive: false,
        },
      },
      {
        path: '/DictionaryManagement',
        name: '字典管理',
        component: () => import('../views/system/DictionaryManagement.vue'),
        meta: {
          title: '字典管理',
          keepAlive: false,
        },
      },
      {
        path: '/Dictionarytype',
        name: '字典类型管理',
        component: () => import('../views/system/Dictionarytype.vue'),
        meta: {
          title: '字典类型管理',
          keepAlive: false,
        },
      },
      {
        path: '/ResourceUpload',
        name: '资源上传',
        component: () => import('../views/system/ResourceUpload.vue'),
        meta: {
          title: '资源下载',
          keepAlive: false,
        },
      },
      {
        path: '/Systemparameter',
        name: '参数配置列表',
        component: () => import('../views/system/Systemparameter.vue'),
        meta: {
          title: '参数配置列表',
          keepAlive: false,
        },
      },
      {
        // 记录查询
        path: '/Inandout',
        name: '进出记录',
        component: () => import('../views/record/Inandout.vue'),
        meta: {
          title: '进出记录',
          keepAlive: false,
        },
      },
      {
        path: '/Parking',
        name: '停车记录',
        component: () => import('../views/record/Parking.vue'),
        meta: {
          title: '停车记录',
          keepAlive: false,
        },
      },
      {
        path: '/Order',
        name: '订单记录',
        component: () => import('../views/record/Order.vue'),
        meta: {
          title: '订单记录',
          keepAlive: false,
        },
      },
      {
        path: '/invoice',
        name: '开票记录',
        component: () => import('../views/record/invoiceRecord.vue'),
        meta: {
          title: '开票记录',
          keepAlive: false,
        },
      },
      {
        path: '/ErrOrder',
        name: '异常记录',
        component: () => import('../views/record/ErrOrder.vue'),
        meta: {
          title: '异常记录',
          keepAlive: false,
        },
      },
      {
        path: '/regionalOrder',
        name: '区域订单记录',
        component: () => import('../views/record/regionalOrder.vue'),
        meta: {
          title: '区域订单记录',
          keepAlive: false,
        },
      },
      {
        path: '/Neworder',
        name: '订单记录',
        component: () => import('../views/record/Neworder.vue'),
        meta: {
          title: '订单记录',
          keepAlive: false,
        },
      },
      {
        // 车场管理
        path: '/vehicle',
        name: '停车场列表',
        component: () => import('../views/Parkinglot/vehicle.vue'),
        meta: {
          title: '停车场列表',
          keepAlive: false,
        },
      },
      {
        path: '/passageway',
        name: '通道列表',
        component: () => import('../views/Parkinglot/passageway.vue'),
        meta: {
          title: '通道列表',
          keepAlive: false,
        },
      },
      {
        path: '/present',
        name: '在场车辆',
        component: () => import('../views/Parkinglot/present.vue'),
        meta: {
          title: '在场车辆',
          keepAlive: false,
        },
      },
      {
        path: '/Fixedcar',
        name: '固定车查询',
        component: () => import('../views/Parkinglot/Fixedcar.vue'),
        meta: {
          title: '固定车查询',
          keepAlive: false,
        },
      },
      {
        path: '/Paymentmethod',
        name: '支付方式',
        component: () => import('../views/Parkinglot/Paymentmethod.vue'),
        meta: {
          title: '支付方式',
          keepAlive: false,
        },
      },
      {
        // 优惠券
        path: '/Register',
        name: '商家注册',
        component: () => import('../views/coupon/Register.vue'),
        meta: {
          title: '商家注册',
          keepAlive: false,
        },
      },
      {
        path: '/Administration',
        name: '商家管理',
        component: () => import('../views/coupon/Administration.vue'),
        meta: {
          title: '商家管理',
          keepAlive: false,
        },
      },
      {
        path: '/perinformation',
        name: '商家管理详情',
        component: () => import('../views/coupon/perinformation.vue'),
        meta: {
          title: '商家管理详情',
          keepAlive: false,
        },
      },
      {
        path: '/Quotaview',
        name: '商家额度查看',
        component: () => import('../views/coupon/Quotaview.vue'),
        meta: {
          title: '商家额度查看',
          keepAlive: false,
        },
      },
      {
        path: '/Coupons',
        name: '商家优惠券',
        component: () => import('../views/coupon/Coupons.vue'),
        meta: {
          title: '商家优惠券',
          keepAlive: false,
        },
      },
      {
        path: '/Purchase',
        name: '商家购买记录',
        component: () => import('../views/coupon/Purchase.vue'),
        meta: {
          title: '商家购买记录',
          keepAlive: false,
        },
      },
      {
        path: '/Purchases',
        name: '商家购买记录',
        component: () => import('../views/coupon/Purchases.vue'),
        meta: {
          title: '商家购买记录',
          keepAlive: false,
        },
      },
      {
        path: '/MoneyLog',
        name: '额度明细',
        component: () => import('../views/coupon/MoneyLog.vue'),
        meta: {
          title: '额度明细',
          keepAlive: false,
        },
      },
      {
        path: '/Collectcoupons',
        name: '发放优惠券',
        component: () => import('../views/coupon/Collectcoupons.vue'),
        meta: {
          title: '发放优惠券',
          keepAlive: false,
        },
      },
      {
        path: '/Merchanthomepage',
        name: '商家首页',
        component: () => import('../views/coupon/Merchanthomepage.vue'),
        meta: {
          title: '商家首页',
          keepAlive: false,
        },
      },
      {
        // 停车用户查询
        path: '/Userquery',
        name: '停车用户查询',
        component: () => import('../views/userquery/Userquery.vue'),
        meta: {
          title: '停车用户查询',
          keepAlive: false,
        },
      },
      {
        // 建行日汇总
        path: '/Dailysummary',
        name: '建行日汇总',
        component: () => import('../views/dailysummary/Dailysummary.vue'),
        meta: {
          title: '建行日汇总',
          keepAlive: false,
        },
      },
      {
        // 对账日志
        path: '/Reconciliationlog',
        name: '对账日志',
        component: () =>
          import('../views/reconciliationlog/Reconciliationlog.vue'),
        meta: {
          title: '对账日志',
          keepAlive: false,
        },
      },
      {
        // 订单统计
        path: '/Orderstatistics',
        name: '订单统计',
        component: () => import('../views/dailysummary/Orderstatistics.vue'),
        meta: {
          title: '订单统计',
          keepAlive: false,
        },
      },
      {
        // 未支付
        path: '/Unpaid',
        name: '未支付',
        component: () => import('../views/dailysummary/Unpaid.vue'),
        meta: {
          title: '未支付',
          keepAlive: false,
        },
      },
      {
        // 账单日汇总
        path: '/Billdaysummary',
        name: '账单日汇总',
        component: () => import('../views/dailysummary/Billdaysummary.vue'),
        meta: {
          title: '账单日汇总',
          keepAlive: true,
        },
      },
    ],
  },
  {
    // 账单日汇总
    path: '/Physicalcode',
    name: '实体券',
    component: () => import('../views/coupon/physicalcode.vue'),
    meta: {
      title: '实体券',
      keepAlive: true,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// router.beforeEach((to, from, next) => {
//   if (to.path === '/Login') return next()
//   const tokenStr = VueCookies.get('token')
//   if (!tokenStr) return next('/Login')
//   next()
// })

router.beforeEach((to, from, next) => {
  const tokenStr = VueCookies.get('token');
  const pass = VueCookies.get('setifuserweakPassword');
  let Passwords = '';
  // 账号是否弱密码
  if (pass) {
    var bytes = CryptoJS.AES.decrypt(pass, 'secret key 741');
    Passwords = bytes.toString(CryptoJS.enc.Utf8);
  }
  if (to.path === '/perinformation') {
    // 判断是否是商家账号
    if (window.sessionStorage.getItem('showCorrect') === 'true') {
      Message.info('该用户不是商家账号，不能访问');
      return;
    }
  }
  //登录状态判断
  if (tokenStr) {
    if (Passwords === 'true') {
      if (to.path == '/changePassword') {
        next();
        return;
      }
      next({
        path: '/changePassword',
      });
    } else {
      next();
    }
  } else {
    if (to.path === '/Login') {
      next();
    } else {
      next({
        path: '/Login',
      });
    }
  }
  to.meta.keepAlive = true;
});
export default router;
