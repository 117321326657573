/*
 * @Author: sun
 * @Date: 2022-03-15 10:50:55
 * @LastEditTime: 2022-12-29 14:52:15
 * @FilePath: \cloud-platform\src\store\getters.js
 * @Description:
 */
// 通常通过getters取数据 (this.$store.getters.news;)
export const newVal = (state) => state.val // 不做其他处理 直接映射出去
export const getTabs = (state) => state.tabList // 获取tbsList
export const getactiveTab = (state) => state.activeTab // 获取当前活跃Tab 路由
export const getactivetabTitle = (state) => state.activeTag // 获取当前活跃Tab 路由名称
export const getdadavid = (state) => state.dadavid // 获取当前用户davavId
export const getshowCorrect = (state) => state.showCorrect // 获取当前是否是管理员
